import axios from 'axios';
import {
  DateRangeDto,
  FileEntity,
  GanttTask,
  GeofencePatch,
  JobDistanceDto,
  JobEntity,
  JobFilters,
  JobHoldEntity,
  JobPatch,
  JobProgressDto,
  JobStatusSummaryDto,
  JobTaskActivityEntity,
  JobTaskAssignmentDto,
  JobTaskDto,
  JobTaskEntity,
  JobTaskGist,
  JobTaskMessageSummaryDto,
  JobTaskReportDto,
  JobTaskScheduleToPost,
  JobTaskStatsDto,
  JobTaskView,
  JobTaskWarningDto,
  MessageEntity,
  PageJobEntity,
} from '../types/open-api/types';
import { OptionalId, PaginationType } from '../types/types';
import { DateFormattingUtils } from '../utils/DataFormattingUtils';

export const JobService = {
  async holdJob(id: number | string, descr?: string, type?: string, startDate?: Date, endDate?: Date, jobTaskId?: number | string): Promise<JobHoldEntity> {
    const response = await axios.post(`/common/jobs/${id}/holds`, {
      "type": type,
      "descr": descr,
      startDate: DateFormattingUtils.formatDateForPayload(startDate),
      endDate: DateFormattingUtils.formatDateForPayload(endDate),
      "jobTaskId": jobTaskId
    });
    return response.data;
  },
  async fetchAll(): Promise<PageJobEntity> {
    const response = await axios.get('/common/jobs');
    return response.data;
  },
  async fetchUnscheduled(
    nextDays: number | string,
    tradeTaskIds?: number[] | string[],
    assigneeIds?: number[] | string[],
    subdivisionIds?: number[] | string[],
    jobTaskIds?: number[] | string[],
    confidence?: number | string
  ): Promise<JobTaskAssignmentDto[]> {
    const response = await axios.get('/common/jobs/unscheduled-tasks', {
      params: {
        nextDays,
        tradeTaskIds,
        assigneeIds,
        subdivisionIds,
        jobTaskIds,
        minConfIndex: confidence,
      },
    });
    return response.data;
  },
  // async fetchUnscheduledAssignees(id: number | string): Promise<JobTaskScheduleToDto[]> {
  //   const response = await axios.get(`/common/jobs/unscheduled-tasks/${id}`);
  //   return response.data;
  // },
  async saveUnscheduledTasks(tasksPayload: JobTaskScheduleToPost[]) {
    const response = await axios.post('/common/jobs/unscheduled-tasks', tasksPayload);
    return response.data;
  },
  async fetchStatusSummary(): Promise<JobStatusSummaryDto[]> {
    const response = await axios.get('/common/jobs/status-summary');
    return response.data;
  },
  async releaseJob(id: number | string, releaseDate: Date): Promise<JobEntity> {
    const response = await axios.post(`/common/jobs/${id}/release`, releaseDate);
    return response.data;
  },
  async fetchFilters(
    builderId?: number | string,
    projectId?: number | string,
    subdivisionId?: number | string,
    planId?: number | string
  ): Promise<JobFilters> {
    const response = await axios.get('/common/jobs/filters', {
      params: {
        builderId,
        projectId,
        subdivisionId,
        planId,
      },
    });
    return response.data;
  },
  async fetchAllFiltered(
    subdivisionId: number | string,
    status: string,
    planId: number | string | undefined,
    projectId?: number | string | undefined,
    pagination?: PaginationType,
    filter?: string
  ): Promise<PageJobEntity> {
    const response = await axios.get('/common/jobs', {
      params: {
        subdivisionId,
        status,
        planId,
        projectId,
        page: pagination?.pageNumber || 0,
        size: pagination?.pageSize || 10,
        search: filter || '',
      },
    });
    return response.data;
  },
  async fetchAllPaged(
    filter: string,
    pageSize: number,
    pageNumber: number,
    sortColumn: string,
    sortDirection: string
  ): Promise<PageJobEntity> {
    const response = await axios.get('/common/jobs/paged', {
      params: {
        filter,
        page: pageNumber,
        size: pageSize,
        sort: [sortColumn, sortDirection].join(','),
      },
    });
    return response.data;
  },
  async fetchById(id: number | string): Promise<JobEntity> {
    const response = await axios.get(`/common/jobs/${id}`);
    return response.data;
  },
  async updateJobSchedule(id: number | string): Promise<JobEntity> {
    const response = await axios.post(`/common/jobs/${id}/schedule`);
    return response.data;
  },
  async fetchJobSchedule(id: number | string): Promise<GanttTask[]> {
    const response = await axios.get(`/common/jobs/${id}/schedule`);
    return response.data;
  },
  async createJobScheduleFromGanttChart(id: number | string, ganttData: any[]) {
    const response = await axios.post(`/common/jobs/${id}/schedule`, ganttData);
    return response.data;
  },
  async save(job: OptionalId<JobPatch>): Promise<JobEntity> {
    let response;
    if (job.id) {
      response = await axios.patch(`/common/jobs/${job.id}`, job);
    } else {
      response = await axios.post('/common/jobs/', job);
    }

    return response.data;
  },
  async delete(id: number | string) {
    const response = await axios.delete(`/common/jobs/${id}`);
    return response.data;
  },
  files: {
    async fetchAllJobFiles(id: number | string): Promise<FileEntity[]> {
      const response = await axios.get(`/common/jobs/${id}/files`);
      return response.data;
    },
    async uploadFile(jobId: number | string, multipartFile: File): Promise<FileEntity> {
      const response = await axios.post(
        `/common/jobs/${jobId}/files`,
        {
          multipartFile,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    },
  },
  jobTasks: {
    async fetchConditions(jobTaskId: number | string): Promise<JobTaskGist[]> {
      const response = await axios.get(`/common/jobs/tasks/${jobTaskId}/conditions`);
      return response.data;
    },
    async fetchStats(): Promise<JobTaskStatsDto[]> {
      const response = await axios.get('/common/jobs/tasks/stats');
      return response.data;
    },
    async fetchReport(
      fromDate: Date,
      thruDate: Date,
      tradeTaskId?: number,
      subOrgId?: number,
      builderId?: number,
      projectId?: number
    ): Promise<JobTaskReportDto[]> {
      const response = await axios.get('/common/jobs/tasks/report', {
        params: {
          fromDate: DateFormattingUtils.formatDateForPayload(fromDate),
          thruDate: DateFormattingUtils.formatDateForPayload(thruDate),
          tradeTaskId,
          subOrgId,
          builderId,
          projectId,
        },
      });
      return response.data;
    },
    async fetchById(id: number | string): Promise<JobTaskEntity> {
      const response = await axios.get(`/common/jobs/tasks/${id}`);
      return response.data;
    },
    async fetchViewById(id: number | string): Promise<JobTaskView> {
      const response = await axios.get(`/common/jobs/tasks/${id}/view`);
      return response.data;
    },
    async fetchWarningsByJobId(id: number | string): Promise<JobTaskWarningDto[]> {
      const response = await axios.get(`/common/jobs/${id}/warnings`);
      return response.data;
    },
    async fetchWarningsByJobTaskId(jobTaskId: number | string): Promise<JobTaskWarningDto[]> {
      const response = await axios.get(`/common/jobs/tasks/${jobTaskId}/warnings`);
      return response.data;
    },
    async fetchVisits(jobTaskId: number | string): Promise<JobTaskActivityEntity[]> {
      const response = await axios.get(`/common/jobs/tasks/${jobTaskId}/visits`);
      return response.data;
    },
    async fetchCurrentOnSite(): Promise<JobTaskDto[]> {
      const response = await axios.get('/common/jobs/tasks/onsite');
      return response.data;
    },
    async fetchNearbyJobs(lat: number, lng: number): Promise<JobDistanceDto[]> {
      const response = await axios.get('/common/jobs/near-by', {
        params: {
          fromLat: lat,
          fromLon: lng,
        },
      });
      return response.data;
    },
    getAssignedTasks: async (userId: number = undefined): Promise<JobTaskView[]> => {
      const response = await axios.get('/common/jobs/tasks', {
        params: {
          assigneeId: userId,
        },
      });
      return response.data;
    },
    saveGeofence: async (geofenceRegion: GeofencePatch[]) => {
      const response = await axios.post('/common/jobs/tasks/geofence', geofenceRegion);
      return response.data;
    },
    async fetchUserSchedule(personId: number | string): Promise<DateRangeDto[]> {
      const response = await axios.get(`/common/jobs/scheduled-tasks/${personId}`);
      return response.data;
    },
    async fetchAll(jobId: number | string, fetchAll = true): Promise<JobTaskView[]> {
      const response = await axios.get(`/common/jobs/${jobId}/tasks`, {
        params: {
          all: fetchAll,
        },
      });
      return response.data;
    },
    async fetchJobProgressGraph(jobId: number | string): Promise<JobProgressDto[]> {
      const response = await axios.get(`/common/jobs/${jobId}/job-progress`);
      return response.data;
    },
    async schedule(
      jobTaskId: number | string,
      startDate: string,
      endDate: string,
      assignees: string[],
      note?: string,
      labor?: number | string
    ): Promise<JobTaskEntity> {
      const response = await axios.post(`/common/jobs/tasks/${jobTaskId}/schedule`, {
        startDate,
        endDate,
        assignees,
        note,
        labor,
      });
      return response.data;
    },
    async save(
      jobTaskId: number | string,
      taskPatch: {
        builderStartDate?: string | undefined;
        builderEndDate?: string | undefined;
        scheduledStartDate?: string | undefined;
        scheduledEndDate?: string | undefined;
        actualStartDate?: string | undefined;
        actualEndDate?: string | undefined;
        pctComplete?: number | undefined;
      }
    ) {
      const response = await axios.patch(`/common/jobs/tasks/${jobTaskId}`, {
        ...taskPatch,
      });
      return response.data;
    },
    async saveDates(
      jobTaskId: number | string,
      builderStartDate: string | undefined = undefined,
      builderEndDate: string | undefined = undefined,
      scheduledStartDate: string | undefined = undefined,
      scheduledEndDate: string | undefined = undefined,
      actualStartDate: string | undefined = undefined,
      actualEndDate: string | undefined = undefined
    ) {
      const response = await axios.patch(`/common/jobs/tasks/${jobTaskId}`, {
        builderStartDate,
        builderEndDate,
        scheduledStartDate,
        scheduledEndDate,
        actualStartDate,
        actualEndDate,
      });
      return response.data;
    },
    messaging: {
      async markJobTaskMessagesAsRead(jobTaskId: number | string) {
        const response = await axios.post(`/common/messages/job-tasks/${jobTaskId}/read`);
        return response.data;
      },
      async postMessage(
        jobTaskId: number | string,
        message: string,
        replyToMessageId?: number
      ): Promise<MessageEntity> {
        const response = await axios.post(`/common/messages/job-tasks/${jobTaskId}`, {
          content: message,
          replyToMessageId,
        });
        return response.data;
      },
      async fetchJobTaskMessageThreads(
        jobId: number | string
      ): Promise<JobTaskMessageSummaryDto[]> {
        const response = await axios.get(`/common/messages/jobs/${jobId}/job-tasks`);
        return response.data;
      },
      async fetchJobTaskMessageThreadsByJobTaskId(
        jobTaskId: number | string
      ): Promise<JobTaskMessageSummaryDto[]> {
        const response = await axios.get(`/common/messages/jobs/job-tasks/${jobTaskId}/summary`);
        return response.data;
      },
      async fetchJobTaskMessagesByJobTaskId(jobTaskId: number | string): Promise<MessageEntity[]> {
        const response = await axios.get(`/common/messages/jobs/job-tasks/${jobTaskId}`);
        return response.data;
      },
      async fetchJobTaskMessages(jobId: number, jobTaskIds?: number[]): Promise<MessageEntity[]> {
        const response = await axios.get(`/common/messages/jobs/${jobId}`, {
          params: {
            jobTaskIds,
          },
        });
        return response.data;
      },
    },
  },
};
